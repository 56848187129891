const colores = {
    fondoLight: 'rgb(242, 242, 242)',
    mainLight: 'rgba(228, 235, 241,0.7)',
    mainLight2: 'rgba(208, 214, 203,0.7)',
    mainLightfull: 'rgb(228, 235, 241)',
    mainDark: 'rgba(110, 127, 141,0.7)',
    mainDarkfull: 'rgb(110, 127, 141)',
    ejesBlue: 'rgba(14, 21, 181,0.8)',
    ejesGreen: 'rgba(4, 145, 44,0.8)',
    ejesOrange: 'rgba(214, 126, 19,0.8)',
    ejesPurple: 'rgba(194, 27, 183,0.8)',
    cardGradiant1: 'rgba(194, 27, 183,0.4)',
    cardGradiant2: 'rgba(4, 145, 44,0.4)',
    cardGradiant3: 'rgba(214, 126, 19,0.4)',
    cardGradiant4: 'rgba(14, 21, 181,0.4)',
    cardGradiant1full: 'rgba(194, 27, 183,1)',
    cardGradiant2full: 'rgba(4, 145, 44,1)',
    cardGradiant3full: 'rgba(214, 126, 19,1)',
    cardGradiant4full: 'rgba(14, 21, 181,1)',
    cardGrTex1: 'rgb(120, 18, 99)',
    cardGrTex2: 'rgb(16, 99, 23)',
    cardGrTex3: 'rgb(125, 60, 17)',
    cardGrTex4: 'rgb(13, 25, 97)',
    cardGrIco1: 'rgba(120, 18, 99,0.2)',
    cardGrIco2: 'rgba(16, 99, 23,0.2)',
    cardGrIco3: 'rgba(125, 60, 17,0.2)',
    cardGrIco4: 'rgba(13, 25, 97,0.2)',
    ejeCity2: 'rgba(13, 25, 97,0.2)',
    jj: 'rgba(242, 242, 242,0.2)',
    gamAmain: '#6a88c',
    gamAdarck: '#5f7174',
    gamAalt1: '#00a6c0',
    gamaAalt2: '#32d9cb',
    gamBmain: '#febbbb',
    gamBdark: 'rgba(21, 42, 104,.7)',
    gamBdarkfull: 'rgba(21, 42, 104)',
    gamblight: '#fdf0f0',
    darkBlueNav: '#120e4d',
    letrajor: '#00AFE8',
    letrajor08: 'rgba(0, 175, 232,.8)',
    ejeGenero: 'rgba(120, 18, 99,0.2)',
    ejeAmbiente: 'rgba(16, 99, 23,0.2)',
    ejeProduccion: 'rgba(125, 60, 17,0.2)',
    ejeBarrios: 'rgba(13, 25, 97,0.2)',
    ejeCity: 'rgba(0, 175, 232,.2)',
    ejeInclucion: 'rgba(111, 60, 166,.2)',
    gradiantBlue:
        'linear-gradient(to right top, #23467a, #04689d, #008cbb, #00b0d2, #1ad5e3)',
    gradiantBlue2:
        ' background-image: radial-gradient(circle, #00afe8, #0090d3, #0071bb, #0052a1, #053484)',
    gradiantGreen:
        'linear-gradient(to right top, #b0e55d, #a7e55f, #9de561, #93e564, #89e567, #80e467, #77e468, #6de369, #63e267, #57e166, #4adf65, #39de64)'
};

/* const jorweb = {
    letrajor: '#00AFE8'
}; */

export default colores;
