import {
    Grid,
    TextField,
    Button,
    Typography,
    FormControl,
    MenuItem,
    makeStyles,
    InputLabel,
    Select,
    Divider
} from '@material-ui/core';
import { useState } from 'react';
import defaultImage from '../../assets/noimg.jpg';
import noticiasService from '../../helpers/noticiasService';
import { actualizarArchivo } from '../../helpers/fileUpload';
import colores from '../../styles/colors';
import CardNoticia from '../cardNoticia';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 20
    }
}));

const PrensaTab = () => {
    return (
        <>
            <FormularioPrensa />
        </>
    );
};

const FormularioPrensa = () => {
    const classes = useStyles();
    const [file, setFile] = useState();
    const [nota, setNota] = useState({
        title: '',
        subtitle: '',
        text: '',
        date: '',
        img: '',
        eje: '',
        color: ''
    });

    function validateForm() {
        return (
            nota.title.length > 0 &&
            nota.subtitle.length > 0 &&
            nota.text.length > 0 &&
            nota.date.length > 0 &&
            nota.eje.length > 0 &&
            file !== undefined
        );
    }
    console.log('esta data ', nota);
    async function handleSubmit() {
        await noticiasService
            .crearNoticia(nota)
            .then(async (data) => {
                const id = data.noticia._id;
                const result = await actualizarArchivo(file, id);

                if (result) return alert('nota creada con exito');
                else return alert('error al  cargar imagen nota');
            })
            .catch((err) => {
                console.log(err.response);
                alert('error al cargar la nota');
            });
    }

    function handlerColor(val) {
        switch (val) {
            case 'ambiente':
                return colores.cardGrIco2;
            case 'barrios':
                return colores.cardGrIco4;
            case 'genero':
                return colores.cardGrIco1;
            case 'produccion':
                return colores.cardGrIco3;
            default:
                break;
        }
    }

    return (
        <>
            <Grid container spacing={5}>
                <Grid item sm={6}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        {keys.map((dta) => {
                            if (dta.type === 'area')
                                return (
                                    <TextField
                                        onChange={(e) =>
                                            setNota({
                                                ...nota,
                                                [dta.key]: e.target.value
                                            })
                                        }
                                        value={nota[dta.key]}
                                        variant="filled"
                                        id={dta.key}
                                        label={dta.key}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        style={{ marginBottom: 20 }}
                                    />
                                );
                            return (
                                <TextField
                                    onChange={(e) =>
                                        setNota({
                                            ...nota,
                                            [dta.key]: e.target.value
                                        })
                                    }
                                    value={nota[dta.key]}
                                    size="small"
                                    variant="filled"
                                    type={dta.type === 'date' && 'date'}
                                    id={dta.key}
                                    label={dta.type !== 'date' && dta.key}
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                />
                            );
                        })}
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                            fullWidth
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Tipo
                            </InputLabel>
                            <Select
                                labelId="ejes"
                                id="eje"
                                value={nota['eje']}
                                onChange={(e) =>
                                    setNota({
                                        ...nota,
                                        eje: e.target.value,
                                        color: handlerColor(e.target.value)
                                    })
                                }
                                label={nota['eje']}
                            >
                                <MenuItem value="ambiente">ambiente</MenuItem>
                                <MenuItem value="barrios">barrios</MenuItem>
                                <MenuItem value="genero">genero</MenuItem>
                                <MenuItem value="produccion">
                                    produccion
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <ImageModal setFile={setFile} />
                    <Button
                        onClick={() => {
                            handleSubmit();
                        }}
                        style={{ marginTop: 20 }}
                        disabled={!validateForm()}
                        fullWidth
                        color="primary"
                        variant="contained"
                    >
                        Cargar noticia
                    </Button>
                </Grid>
                <Grid item sm={6}>
                    <CardNoticia data={nota} />
                </Grid>
            </Grid>
            <Divider style={{ margin: 80 }} />
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    fsafas
                </Grid>
            </Grid>
        </>
    );
};

const ImageModal = ({ setFile }) => {
    const [imagenn, setImagenn] = useState();

    function mostrarImagen(file) {
        setFile(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
            setImagenn(reader.result);
        };
    }

    return (
        <Grid
            container
            spacing={3}
            direction="column"
            style={{ paddingRight: 50, paddingLeft: 50 }}
        >
            <Typography
                style={{ marginTop: 5, marginBottom: 5, textAlign: 'center' }}
            >
                Seleccionar Imagen
            </Typography>
            <Button
                variant="contained"
                component="label"
                style={{ width: 240, margin: 'auto', height: '170px' }}
            >
                {imagenn ? (
                    <img
                        className="shadow mt-3"
                        style={{ height: '170px', width: '260px' }}
                        src={imagenn}
                        alt="imk1"
                    />
                ) : (
                    <img
                        className="shadow mt-3"
                        style={{ height: '170px', width: '260px' }}
                        src={defaultImage}
                        alt="imk2"
                    />
                )}
                <input
                    onChange={(e) => {
                        mostrarImagen(e.target.files[0]);
                    }}
                    type="file"
                    hidden
                />
            </Button>
        </Grid>
    );
};

const keys = [
    { key: 'title', type: 'normal' },
    { key: 'subtitle', type: 'normal' },
    { key: 'text', type: 'area' },
    { key: 'date', type: 'date' }
];

export default PrensaTab;
