import { useState } from 'react';
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    makeStyles,
    Typography
} from '@material-ui/core';
import noimg from '../assets/noimg.jpg';
import { isMobile } from 'react-device-detect';

const CardNoticia = ({ data, setClickNota }) => {
    const [shadow, setShadow] = useState(1);
    const classes = useStyles(data);

    return (
        <Card
            onClick={() => setClickNota(data)}
            elevation={shadow}
            className={classes.cardd}
            onMouseEnter={() => setShadow(5)}
            onMouseLeave={() => setShadow(1)}
        >
            <CardActionArea>
                <CardMedia
                    image={data.img || noimg}
                    className={classes.mediaimg}
                />
                <CardContent className={classes.cardCont}>
                    <Typography className={classes.letraTitle}>
                        {data.title.slice(0, 116)}
                    </Typography>
                    <Typography className={classes.letratxt}>
                        {data.subtitle.slice(0, 210)} ...leer mas
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    paddingRight: 20,
                    backgroundColor: 'rgba(255,255,255,.3)'
                }}
            >
                <Typography> {data.date && data.date.slice(0, 10)}</Typography>
            </CardActions>
        </Card>
    );
};

const useStyles = makeStyles(() => ({
    cardCont: { minHeight: !isMobile && 120, padding: 25 },
    letraTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10
    },
    letratxt: {
        fontSize: 15
    },
    cardd: {
        marginTop: isMobile ? 15 : 30,
        backgroundColor: (data) => data.color,
        borderRadius: 5,
        margin: 'auto',
        cursor: 'pointer',
        overflow: 'auto'
    },
    mediaimg: {
        height: isMobile ? 160 : 220
    }
}));

export default CardNoticia;
