const ContainerTab = ({ children }) => {
    return (
        <div
            style={{
                paddingRight: 140,
                paddingLeft: 140,
                paddingTop: 10
                //backgroundColor: 'transparent'
            }}
        >
            {children}
        </div>
    );
};

export default ContainerTab;
