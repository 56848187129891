import React, { Suspense, lazy, useState, useEffect } from 'react';
import './App.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './helpers/protectedRoute';
import PrensaDash from './pages/prensaDash';
import FormProvider from './context/formContext';
import Loader from './components/loader';

const Login = lazy(() => import('./pages/login'));
const Home = lazy(() => import('./pages/index'));

const theme = createMuiTheme({
    typography: {
        fontFamily: ['Sora', 'sans-serif'].join(',')
    }
});

const themeGradiant = {
    backgroundBlue:
        'linear-gradient(to right top, #a59dda, #998ed8, #8c7fd5, #8070d2, #7461ce)',
    backgroundGreen:
        'linear-gradient(to right top, #b0e55d, #a7e55f, #9de561, #93e564, #89e567, #80e467, #77e468, #6de369, #63e267, #57e166, #4adf65, #39de64)',
    backgroundBlueDark:
        'linear-gradient(to left top, #0472c2, #0d65b2, #1158a1, #134b91, #123f81)',
    backgroundOrange:
        'linear-gradient(to right top, #daca9d, #d4bb82, #d0ab69, #cc9a50, #c88938)',
    backgroundPurple:
        'linear-gradient(to right top, #cf9dda, #c98bd6, #c278d2, #bb65ce, #b450c9)',
    backgroundJor:
        'linear-gradient(to right top, #76ddff, #62d2f9, #4cc6f3, #32bbee, #00afe8)',
    backgroundCool1:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)'
};
function App() {
    return (
        <ThemeProvider theme={theme}>
            <ThemeProvider theme={themeGradiant}>
                <CssBaseline />

                <FormProvider>
                    <Router>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route exact path="/" component={Home} />

                                <Route exact path="/logg" component={Login} />

                                <ProtectedRoute
                                    exact
                                    path="/fform"
                                    component={PrensaDash}
                                />
                                <Route path="*" component={Home} />
                            </Switch>
                        </Suspense>
                    </Router>
                </FormProvider>
            </ThemeProvider>
        </ThemeProvider>
    );
}

export default App;
