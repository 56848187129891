import {
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import ProyectoService from '../../helpers/proyectoService';
import colores from '../../styles/colors';
import CardProyect from '../cardProyect';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 20
    }
}));

const ProyectTab = () => {
    return (
        <>
            <FormularioProyectos />
        </>
    );
};

const FormularioProyectos = () => {
    const classes = useStyles();
    const [nota, setNota] = useState({
        title: '',
        subtitle: '',
        eje: '',
        tipo: '',
        text: '',
        color: ''
    });

    function handlerColor(val) {
        switch (val) {
            case 'ambiente':
                return colores.ejeAmbiente;
            case 'barrios y obras':
                return colores.ejeBarrios;
            case 'genero y diversidad':
                return colores.ejeGenero;
            case 'produccion':
                return colores.ejeProduccion;
            case 'produccion y ambiente':
                return colores.ejeProduccion;
            case 'la ciudad que queremos':
                return colores.ejeCity;
            case 'inclusión y participación':
                return colores.ejeInclucion;
            default:
                break;
        }
    }

    async function handleSubmit() {
        await ProyectoService.crearProyecto(nota)
            .then((res) => {
                if (res.ok) alert('proyecto creado con exito');
                else alert('ocurrio algun error');
            })
            .catch((err) => {
                console.log(err.response);
                alert('error al cargar la nota');
            });
    }

    return (
        <Grid container spacing={5}>
            <Grid item sm={6}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <TextField
                        onChange={(e) =>
                            setNota({
                                ...nota,
                                title: e.target.value
                            })
                        }
                        value={nota['title']}
                        size="small"
                        variant="filled"
                        id={'title'}
                        label={'title'}
                        fullWidth
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        onChange={(e) =>
                            setNota({
                                ...nota,
                                subtitle: e.target.value
                            })
                        }
                        value={nota['subtitle']}
                        size="small"
                        variant="filled"
                        id={'subtitle'}
                        label={'subtitle'}
                        fullWidth
                        multiline
                        rows={3}
                        style={{ marginBottom: 20 }}
                    />
                    <TextField
                        onChange={(e) =>
                            setNota({
                                ...nota,
                                text: e.target.value
                            })
                        }
                        value={nota['text']}
                        size="small"
                        variant="filled"
                        id={'text'}
                        label={'text'}
                        fullWidth
                        multiline
                        rows={6}
                        style={{ marginBottom: 20 }}
                    />
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Eje
                        </InputLabel>
                        <Select
                            labelId="ejes"
                            id="eje"
                            value={nota['eje']}
                            onChange={(e) =>
                                setNota({
                                    ...nota,
                                    eje: e.target.value,
                                    color: handlerColor(e.target.value)
                                })
                            }
                            label={nota['eje']}
                        >
                            <MenuItem value="ambiente">ambiente</MenuItem>
                            <MenuItem value="barrios y obras">
                                barrios y obras
                            </MenuItem>
                            <MenuItem value="genero y diversidad">
                                genero y diversidad
                            </MenuItem>
                            <MenuItem value="produccion">produccion</MenuItem>
                            <MenuItem value="produccion y ambiente">
                                produccion y ambiente
                            </MenuItem>
                            <MenuItem value="la ciudad que queremos">
                                la ciudad que queremos
                            </MenuItem>
                            <MenuItem value="inclusión y participación">
                                inclusión y participación
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            tipo
                        </InputLabel>
                        <Select
                            labelId="tipo"
                            id="tipo"
                            value={nota['tipo']}
                            onChange={(e) =>
                                setNota({
                                    ...nota,
                                    tipo: e.target.value
                                })
                            }
                            label={nota['tipo']}
                        >
                            <MenuItem value="PROYECTO DE ORDENANZA">
                                PROYECTO DE ORDENANZA
                            </MenuItem>
                            <MenuItem value="ORDENANZA MUNICIPAL">
                                ORDENANZA MUNICIPAL
                            </MenuItem>
                            <MenuItem value="PROYECTO DE RESOLUCION">
                                PROYECTO DE RESOLUCION
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item sm={3} style={{ margin: 'auto' }}>
                <CardProyect proyect={nota} />
            </Grid>
            <Grid item sm={6}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSubmit()}
                >
                    Cargar
                </Button>
            </Grid>
        </Grid>
    );
};

export default ProyectTab;
