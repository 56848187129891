import { useState } from 'react';
import { Avatar, Card, CardHeader, Fab, Typography } from '@material-ui/core';
import colores from '../styles/colors';
import EcoIcon from '@material-ui/icons/Eco';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import fem from '../assets/iconos/fem.png';
import DomainIcon from '@material-ui/icons/Domain';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PanToolIcon from '@material-ui/icons/PanTool';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { makeStyles } from '@material-ui/styles';

const CardProyect = ({ proyect, setOpenProyect }) => {
    const classes = useStyles();
    const [shadow, setShadow] = useState(2);

    function handlerIcon() {
        switch (proyect.eje) {
            case 'ambiente':
                return <EcoIcon style={{ fontSize: 30 }} />;
            case 'barrios y obras':
                return <DomainIcon style={{ fontSize: 30 }} />;
            case 'genero y diversidad':
                return (
                    <Avatar
                        src={fem}
                        style={{
                            height: 30,
                            width: 30,
                            margin: 'auto',
                            marginTop: 2
                        }}
                    />
                );
            case 'produccion':
                return <EqualizerIcon style={{ fontSize: 30 }} />;
            case 'produccion y ambiente':
                return <EqualizerIcon style={{ fontSize: 30 }} />;
            case 'la ciudad que queremos':
                return <ApartmentIcon style={{ fontSize: 30 }} />;
            case 'inclusión y participación':
                return <PanToolIcon style={{ fontSize: 26 }} />;
            default:
                return <PriorityHighIcon style={{ fontSize: 30 }} />;
        }
    }

    return (
        <Card
            elevation={shadow}
            className={classes.root}
            onMouseEnter={() => setShadow(5)}
            onMouseLeave={() => setShadow(1)}
            onClick={() => setOpenProyect(proyect)}
        >
            <div
                style={{
                    backgroundColor: proyect.color,
                    paddingBottom: '7%',
                    paddingTop: '7%',
                    marginBottom: 10
                }}
            >
                {handlerIcon()}
                <Typography
                    style={{
                        color: colores.darkBlueNav,
                        fontSize: 14,
                        letterSpacing: 3,
                        paddingTop: 10
                    }}
                >
                    {proyect.eje}
                </Typography>
            </div>
            <div
                style={{
                    paddingLeft: '5%',
                    paddingRight: '5%'
                }}
            >
                <CardHeader
                    title={proyect.title.slice(0, 55)}
                    subheader={`${proyect.subtitle.slice(0, 145)} ...leer mas`}
                    titleTypographyProps={{
                        style: {
                            color: colores.darkBlueNav,
                            fontSize: 18,
                            minHeight: 70
                        }
                    }}
                    subheaderTypographyProps={{
                        style: {
                            fontSize: 15,
                            minHeight: 130
                        }
                    }}
                />
            </div>
            <div style={{ marginTop: 20 }}>
                <Typography
                    style={{
                        color: colores.darkBlueNav,
                        letterSpacing: 1.4,
                        fontSize: 14
                    }}
                >
                    {proyect.tipo}
                </Typography>
            </div>
        </Card>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: 430,
        textAlign: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        margin: 'auto',
        transition: `${theme.transitions.create(['transform'])}`,
        '&:hover': {
            transform: 'scale(1.015)'
        }
    }
}));

export default CardProyect;
