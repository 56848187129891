import Axios from 'axios';

const urlTotales = `${process.env.REACT_APP_URL}/vacunas/totales`;
const urlUltimoDia = `${process.env.REACT_APP_URL}/vacunas/ultimodia`;
const urlPorDepartamento = `${process.env.REACT_APP_URL}/vacunas/pordepartamento`;
class VacunaService {
    async getTotales() {
        let totales = [];

        await Axios.get(`${urlTotales}`)
            .then(({ data }) => data.totales)
            .then((res) => {
                res.map((res) => {
                    if (res[0] === 'MODERNA' && res[1] === null) {
                        totales.moderna = res[3];
                    } else if (
                        res[0] === 'ASTRAZENECA/COVISHIELD' &&
                        res[1] === null
                    ) {
                        totales.astrazeneca = res[3];
                    } else if (res[0] === 'SINOPHARM' && res[1] === null) {
                        totales.sinopharm = res[3];
                    } else if (res[0] === 'SPUTNIK-V' && res[1] === null) {
                        totales.sputnik = res[3];
                    } else if (res[0] === null && res[1] === null) {
                        totales.total = res[3];
                    }
                });
            })
            .catch((e) => {
                console.log(e);
                return null;
            });

        return totales;
    }

    async getUltimoDia() {
        let ultimoDia = [];
        await Axios.get(`${urlUltimoDia}`)
            .then(({ data }) => data.vacunas)
            .then((res) =>
                res.map((res) => {
                    if (res[1] === '1º dosis') {
                        ultimoDia.unadosis = res[2];
                        ultimoDia.fecha = res[0];
                    } else if (res[1] === '2º dosis')
                        ultimoDia.dosdosis = res[2];
                    else if (res[1] === 'Total') ultimoDia.totall = res[2];
                })
            )
            .catch((e) => {
                console.log(e);
                return [];
            });

        return ultimoDia;
    }

    async getPorDepartamento() {
        return await Axios.get(`${urlPorDepartamento}`)
            .then(({ data }) => data.vacunas)
            .catch((e) => {
                console.log(e);
                return [];
            });
    }
}

export default new VacunaService();
