import { Backdrop, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import jorL from '../assets/jorLogo.png';

const Loader = () => {
    return (
        <Backdrop open={true}>
            <Backdrop open={true}>
                <img
                    src={jorL}
                    alt="jorita"
                    style={{ width: isMobile ? '65%' : 400 }}
                />

                <CircularProgress
                    style={{ color: '#00AFE8' }}
                    size={isMobile ? 30 : 50}
                    variant="indeterminate"
                />
            </Backdrop>
        </Backdrop>
    );
};

export default Loader;
