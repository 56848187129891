import React, { createContext, Component } from 'react';
import noticiasService from '../helpers/noticiasService';
import proyectoService from '../helpers/proyectoService';
import VacunaService from '../helpers/vacunaService';

export const FormContext = createContext();

class FormProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            noticias: [],
            openModal: false,
            dataModal: [],
            vacunas: []
        };
    }

    async componentDidMount() {
        const noticias = await noticiasService.getNoticias();
        const proyectos = await proyectoService.getProyectos();
        const totales = await VacunaService.getTotales();
        const ultimoDia = await VacunaService.getUltimoDia();

        this.setState({
            noticias: noticias,
            proyectos: proyectos,
            vacunas: { ...totales, ...ultimoDia }
        });
    }

    handlerModal = (data) => {
        this.setState({
            openModal: !this.state.openModal,
            dataModal: data || []
        });
    };

    render() {
        return (
            <FormContext.Provider
                value={{
                    ...this.state,
                    handlerModal: this.handlerModal
                }}
            >
                {this.props.children}
            </FormContext.Provider>
        );
    }
}

export default FormProvider;
