import Axios from 'axios';

class NoticiasService {
    get token() {
        return localStorage.getItem('token') || '';
    }

    get headers() {
        return {
            headers: {
                token: this.token
            }
        };
    }

    async getNoticias() {
        return await Axios.get(`${process.env.REACT_APP_URL}/prensa/noticias`)
            .then(({ data }) =>
                data.noticias.filter((noticias) => noticias._id).reverse()
            )
            .catch((e) => {
                console.log(e);
                return [];
            });
    }

    async crearNoticia(noticia) {
        return await Axios.post(
            `${process.env.REACT_APP_URL}/prensa/noticias`,
            noticia,
            this.headers
        )
            .then(({ data }) => data)
            .catch((e) => {
                console.log(e);
                return null;
            });
    }

    borrarNoticia(_id) {
        const url = `${process.env.REACT_APP_URL}/prensa/noticias/${_id}`;
        return Axios.delete(url, this.headers)
            .then(() => alert('Borrado', '', 'success'))
            .catch((e) => {
                console.log(e);
                return null;
            });
    }

    async actualizarNoticia(id, newNoticia) {
        return await Axios.put(
            `${process.env.REACT_APP_URL}/prensa/noticias/${id}`,
            newNoticia,
            this.headers
        ).catch((e) => {
            console.log(e);
            return null;
        });
    }

    async importantLimit() {
        let count;
        await Axios.get(`${process.env.REACT_APP_URL}/prensa/noticias`)
            .then(({ data }) =>
                data.noticias.filter((noticia) => noticia.important)
            )
            .then((notas) => (count = notas.length))
            .catch((e) => {
                console.log(e);
                return null;
            });

        return count;
    }

    async getNoticiaById(id) {
        return await Axios.get(
            `${process.env.REACT_APP_URL}/prensa/noticias/${id}`
        ).catch((e) => {
            console.log(e);
            return null;
        });
    }

    async getNoticiasPaginadas(desde) {
        return await Axios.get(
            `${process.env.REACT_APP_URL}/prensa/noticias/desde/${desde}`
        ).catch((e) => {
            console.log(e);
            return null;
        });
    }
}

export default new NoticiasService();
