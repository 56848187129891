import Axios from 'axios';

class ProyectoService {
    get token() {
        return localStorage.getItem('token') || '';
    }

    get headers() {
        return {
            headers: {
                token: this.token
            }
        };
    }

    async getProyectos() {
        return await Axios.get(`${process.env.REACT_APP_URL}/proyectos`)
            .then(({ data }) => {
                return data.proyecto
                    .filter((proyecto) => proyecto._id)
                    .reverse();
            })
            .catch((e) => {
                console.log(e);
                return null;
            });
    }

    async crearProyecto(noticia) {
        return await Axios.post(
            `${process.env.REACT_APP_URL}/proyectos`,
            noticia,
            this.headers
        )
            /* .then((res) => res.json()) */
            .then(({ data }) => data)
            .catch((e) => {
                console.log(e);
                return null;
            });
    }

    borrarProyecto(_id) {
        const url = `${process.env.REACT_APP_URL}/proyectos/${_id}`;
        return Axios.delete(url, this.headers)
            .then(() => alert('Borrado', '', 'success'))
            .catch((e) => {
                console.log(e);
                return null;
            });
    }

    async actualizarProyecto(id, newNoticia) {
        return await Axios.put(
            `${process.env.REACT_APP_URL}/proyectos/${id}`,
            newNoticia,
            this.headers
        ).catch((e) => {
            console.log(e);
            return null;
        });
    }

    async getNoticiaById(id) {
        return await Axios.get(
            `${process.env.REACT_APP_URL}/proyectos/${id}`
        ).catch((e) => {
            console.log(e);
            return null;
        });
    }
}

export default new ProyectoService();
