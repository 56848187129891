import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_ANALITYCS_CODE, {
    debug: false,
    titleCase: false,
    gaOptions: {
        userId: 2727332763
    }
});
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();
